@import "./components.css";

body.dev {
    /* background-color: #aaa; */
}

body.dev #segmentation_filter_mount {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 100;
}

body #detail_pane_mount {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 100;
}

body.dev .images-uploader {
    max-width: 327px;
}

body.dev .dev-ui {
    display: none;
}

body.dev .dev-ui.active {
    display: block;
}

body.dev .dev-tabs {
    display: flex;
    margin-top: 10px;
    margin-bottom: 30px;
    border-bottom: 1px solid gray;
}

body.dev .dev-tabs li {
    cursor: pointer;
    background-color: #e8e8e8;
    border: 1px solid #d0d0d0;
    color: gray;
    border-bottom: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 10px 20px;
    margin-right: 20px;
}

body.dev .dev-tabs li.active {
    background-color: #d0d0d0;
}

/* UI Blocker Additions */

.ui-blocker .ui-blocker-text {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
}

.ui-blocker .ui-blocker-text .ui-blocker-text-inner {
    background: #FFFFFF;
    border: 1px solid #aaa;
    border-radius: 4px;
    font-family: Raleway,sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.2px;
    line-height: 14px;
    padding: 10px 24px;
    text-transform: uppercase;
}

/* Modal */

/* TODO Refactor Segmentation Filter to use modal styles */

.modal-header {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 24px;
    background-color: #48baa8;
    color: #fff;
    box-sizing: border-box;
}

.modal-heading {
    display: flex;
    align-items: center;
    height: 50px;
}

.modal-heading h2 {
    margin: 0;
    color: #fff;
}

.modal-header .buttons {
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    top: -3px;
}

.modal-header .buttons .exit-btn {
    -webkit-appearance: none;
    background: none;
    border: none;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    width: 14px;
    height: 14px;
    line-height: 14px;
}

.modal-content {
    padding: 13px;
}

.modal-bottom {
    display: flex;
    height: 80px;
    border-top: 1px solid #e3e2e7;
    background: #fff;
}

.modal-bottom .bottom-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1 1;
    align-self: flex-end;
    padding: 0 25px;
    height: 100%;
}

.modal-bottom button {
    margin-left: 13px;
}


/* Forms */

.styled-form .checkbox-row label {
    line-height: 18px;
    display: inline-block;
    max-width: 90%;
    box-sizing: border-box;
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.styled-form input[type=text]::placeholder {
    color: #939aa4;
}

.styled-form .subtext {
    font-size: smaller;
}

/* Filepond */

.filepond--root {
    margin-bottom: 0;
}

.filepond--panel-root {
    border: 1px solid #E3E2E7;
    border-radius: 4px;
}

.filepond--drop-label label {
    /* .raleway-semi-bold */
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    color: #313233;

    /* .raleway-12 */
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    color: #313233;
}

.filepond--drop-label label .filepond--label-action {
    color: #48BAA8;
    text-decoration-color: #48BAA8;
}

.filepond--drip {
    background-color: #F7F7F7;
}


/* Images Uploader */

.ImagesUploader .buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 9px;
}

.ImagesUploader .images {
    margin: -5px -5px 4px -5px;
}

/* NOTE: When sorting, the sortbale elements are cloned and appended to the
        end of the body tag, so they are temporarily not inside the original
        parent element. */

.images-uploader-image {
    display: inline-block;
    line-height: 0;
    position: relative;
    cursor: grab;
    border-radius: 4px;
    overflow: hidden;
    width: 25%;
    max-width: 100px;
    box-sizing: border-box;
    padding: 5px 5px 2px 5px;
}

.images-uploader-image img {
    width: 100%;
    height: auto;
    border-radius: 4px;
}

/* .images-uploader-image.selected:after,
.images-uploader-image:hover:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
} */

.images-uploader-image .selected-check {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background: #fff; */
    /* border-radius: 50%; */
    width: 20px;
    height: 20px;
    z-index: 1;
    opacity: 0;
    z-index: 2;
}

.images-uploader-image:hover .selected-check {
    opacity: 0.5;
}

.images-uploader-image.selected .selected-check {
    opacity: 1;
}

.images-uploader-image .selected-check img {
    width: 100%;
    height: auto;
}

.images-uploader-image .delete,
.images-uploader-image .view {
    display: none;
    -webkit-appearance: none;
    background-color: #fff;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 3;
    box-shadow: 3px 3px 3px 0px rgba(0,0,0,0.2);
}

.images-uploader-image:hover .delete,
.images-uploader-image:hover .view {
    display: flex;
}

.images-uploader-image .view {
    top: auto;
    right: auto;
    bottom: 8px;
    left: 11px;
}

.images-uploader-image .delete img,
.images-uploader-image .view img {
    width: 8px;
    height: auto;
}

.images-uploader-image .delete img.hover,
.images-uploader-image .view img.hover {
    display: none;
}

.images-uploader-image .delete:hover,
.images-uploader-image .view:hover {
    background-color: #313233;
}

.images-uploader-image .delete:hover img.hover,
.images-uploader-image .view:hover img.hover {
    display: inline-block;
}

.images-uploader-image .delete:hover img:not(.hover),
.images-uploader-image .view:hover img:not(.hover) {
    display: none;
}

.images-uploader-image .view img {
    width: 14px;
}

.images-uploader-image.selected .delete,
.images-uploader-image.selected .view {
    display: none;
}

.react-images__blanket,
.react-images__positioner {
    z-index: 1000 !important;
}

.ImagesUploader .instructions {
    position: relative;
    padding: 26px 14px 0 14px;
}

.ImagesUploader .instructions-toggle {
    position: absolute;
    top: 26px;
    right: 14px;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #e3e2e7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-90deg);
    cursor: pointer;
}

.ImagesUploader .instructions-toggle.expanded {
    transform: rotate(90deg);
}

.ImagesUploader .instructions-toggle img {
    position: relative;
    left: -1px;
    width: 7px;
    height: auto;
}

.ImagesUploader .instructions .separator {
    height: 26px;
    visibility: hidden;
}

.ImagesUploader .instructions h3 {
    margin-bottom: 13px;
}

.ImagesUploader .instructions p {
    text-transform: none;
    margin: 0;
}

.ImagesUploader .instructions p:first-of-type {
    padding-right: 30px;
}

.ImagesUploader.compact {
    position: relative;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 4px;
}

.ImagesUploader.compact .images {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
}

.ImagesUploader.compact .images .gallery {
    display: flex;
    flex: 1;
}

.ImagesUploader.compact .images .images-uploader-image {
    flex: 1;
    padding: 0;
}

.ImagesUploader.compact .images-uploader-image:hover .selected-check {
    opacity: 0;
}

.images-uploader-image.compact .view {
    bottom: 5px;
    left: 5px;
}

.images-uploader-image.compact .delete {
    top: 5px;
    right: 5px;
}

.ImagesUploader.compact .uploader {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    border: 1px solid #E3E2E7;
    background-color: #f7f7f7;
    box-sizing: border-box;
}

.ImagesUploader.compact.has-images .uploader {
    display: none;
}

.ImagesUploader.compact .filepond--wrapper {
    flex: 1;
}

.ImagesUploader.compact .filepond--root {
    display: flex;
    flex: 1;
}

.ImagesUploader.compact .filepond--drip {
    background-color: transparent;
}

.ImagesUploader.compact .filepond--root .filepond--list-scroller {
    margin-top: 2px;
    margin-right: 2px;
    margin-bottom: 2px;
    margin-left: 2px;
}

.ImagesUploader.compact .filepond--list,
.ImagesUploader.compact .filepond--list.filepond--list {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.ImagesUploader.compact .filepond--item {
    margin: 4px 0;
}

.ImagesUploader.compact .filepond--panel {
    /* display: none; */
    border: none;
}

.ImagesUploader.compact.has-files .filepond--panel {
    /* display: none; */
}

.ImagesUploader.compact .filepond--file button:not(.filepond--action-remove-item) {
    display: none;
}

.ImagesUploader.compact .filepond--file-info,
.ImagesUploader.compact .filepond--file-status {
    visibility: hidden;
}

.ImagesUploader.compact .filepond--drop-label.filepond--drop-label label {
    cursor: pointer;
}

.ImagesUploader.compact .filepond--drop-label.filepond--drop-label label img {
    width: 25px;
    position: relative;
    top: -12px;
}


/* Product Variants */

.ProductVariants .product-variants {
    display: flex;
}

.ProductVariants .tabs {
    padding-left: 72px;
}

.ProductVariants .tabs-hrule {
    margin-bottom: 0;
}

.ProductVariants .bulk-select-bar {
    display: flex;
    align-items: center;
    background-color: #F7F7F7;
    padding: 7px 40px;
    border-bottom: 1px solid #E3E2E7;
}

.ProductVariants .bulk-select-bar .select-all {
    width: 32px;
}

.ProductVariants .bulk-select-bar .select-label {
    margin-right: 16px;
}

.ProductVariants .bulk-select-bar .options {
    display: flex;
    flex: 1;
    padding-right: 8px;
}

.ProductVariants .bulk-select-bar .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 0 0 556px;
}

.ProductVariants .bulk-select-bar .buttons .btn {
    margin-left: 16px;
}

.ProductVariants .bulk-select-bar .buttons .btn:first-of-type {
    margin-left: 0;
}

.ProductVariants .bulk-select-bar .options .react-select-container {
    flex: 1;
    margin: 0 8px;
}

.ProductVariants .bulk-select-bar .options .react-select-container:first-of-type {
    margin-left: 0;
}

.ProductVariants .bulk-select-bar .options .react-select-container:last-of-type {
    margin-right: 0;
}

.ProductVariants .bulk-select {
    display: flex;
    width: 72px;
    margin-top: 52px;
    padding-top: 10px;
    border-top: 1px solid #E3E2E7;
}

.ProductVariants .bulk-select ul {
    margin-left: 40px;
}

.ProductVariants .bulk-select ul {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.ProductVariants .variants-table,
.ProductVariants .variants-table table {
    width: 100%;
}

.ProductVariants .variants-table {
    position: relative;
    box-sizing: border-box;
    margin: 0 40px 0 0;
}

.ProductVariants .variants-table:before {
    content: '';
    position: absolute;
    top: 0;
    right: -40px;
    width: 40px;
    height: 53px;
    box-sizing: border-box;
    border-bottom: 1px solid #E3E2E7;
}

.ProductVariants .variants-table th {
    position: relative;
    text-align: center;
    padding: 19px 0;
    border-bottom: 1px solid #E3E2E7;
}

.ProductVariants .variants-table th.sortable {
    cursor: pointer;
}

.ProductVariants .variants-table th.sortable:before {
    content: '';
    position: absolute;
    top: 0;
    right: 8px;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: right center;
    background-image: url(./images/sort_both.png);
    opacity: 0.2;
}

.ProductVariants .variants-table th.sortable.direction-asc:before {
    background-image: url(./images/sort_asc.png);
    opacity: 1;
}

.ProductVariants .variants-table th.sortable.direction-desc:before {
    background-image: url(./images/sort_desc.png);
    opacity: 1;
}

.product-variant-row {
    width: 100%;
}

body > .product-variant-row {
    display: table !important;
}


.product-variant-row td {
    vertical-align: middle;
    padding: 10px 8px;
}

.product-variant-row td:last-of-type {
    padding: 10px 0;
}

.product-variant-row:first-of-type td {
    padding-top: 20px;
}

.ProductVariants .variants-table th.image,
.product-variant-row td.image {
    width: 50px;
}

.ProductVariants .variants-table th.inventory,
.product-variant-row td.inventory,
.ProductVariants .variants-table th.price,
.product-variant-row td.price,
.ProductVariants .variants-table th.sku,
.product-variant-row td.sku {
    width: 112px;
}

.ProductVariants .variants-table th.delete,
.product-variant-row td.delete {
    width: 43px;
}

.product-variant-row td.delete {
    text-align: right;
}

.product-variant-row td.sku input {
    text-transform: none;
}

.product-variant-row .option .react-select-container {
    width: 100%;
}

.product-variant-row.duplicate .react-select-container > div,
.product-variant-row.duplicate-sku td.sku input {
    border-color: #EA2A2A;
}

.product-variant-row.incomplete .react-select-container > div {
    border-color: #FFDF2E;
}

.ProductVariants .product-options .sortable-options {
    width: fit-content;
}

.ProductVariants .product-variants-bottom {
    padding: 20px 40px 0 75px;
}

.ProductVariants .add-variant {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.ProductVariants .add-variant .btn {
    margin-right: 19px;
}

.ProductVariants .add-variant:disabled {
    opacity: 0.3;
}

.product-options {
    padding: 16px 40px 0 75px;
}

.product-option-row {
    display: flex;
    flex: 1;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

body > .product-option-row {
    display: table !important;
}

.product-option-row .option-name {
    flex: 0;
}

.product-option-row .option-name input {
    width: 220px;
    margin-right: 16px;
}

.product-option-row .option-values {
    flex: 1;
}

.product-option-row .option-values .react-select-container {
    flex: 1;
    width: 550px;
    margin-right: 16px;
}

.product-option-row .option-delete {
    flex: 0;
}

.ProductVariants .product-options .bottom {
    padding: 12px 0 0 0;
}

.ProductVariants .add-option {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.ProductVariants .add-option .btn {
    margin-right: 19px;
}


/* Imports/Exports */

.Imports,
.Exports {
    min-width: 700px;
}

.Imports .available-imports,
.Exports .available-exports {
    margin-bottom: 20px;
}

.Imports .available-imports h3,
.Exports .available-exports h3 {
    margin-bottom: 10px;
}

.Imports .import-name,
.Exports .export-name {
    margin-bottom: 20px;
}

.Imports .import-name h3,
.Exports .export-name h3 {
    margin-bottom: 10px;
}

.Imports .import-name input,
.Exports .export-name input {
    padding: 5px;
    line-height: 12px;
    border: 1px solid #e3e2e7;
    box-sizing: border-box;
    width: 340px;
    margin: 0 13px 0 0;
}

.Imports .import-options h3,
.Exports .export-options h3 {
    margin-bottom: 10px;
}

.Imports .import-options .inner,
.Exports .export-options .inner {
    display: flex;
}

.Imports .import-options .upload,
.Exports .export-options .columns {
    padding-right: 10px;
}

.Imports .import-options .options ul,
.Exports .export-options .columns ul {
    overflow-y: scroll;
    max-height: 250px;
}

.Imports .import-options .options,
.Exports .export-options .options {
    padding-left: 10px;
    margin-bottom: 10px;
}

.Imports .import-options .inner > div,
.Exports .export-options .inner > div {
    width: 50%;
}

.Imports .import-options .inner > div {
    flex: 1;
}

.Exports .columns .sensitive {
    color: #EA2A2A;
    margin-left: 10px;
}

.Imports .separator,
.Exports .separator {
    margin: 5px 0;
    border-top: 1px solid #e3e2e7;
}

.Imports .example-download,
.Exports .example-download {
    padding-top: 10px;
}

.Imports .example-download button,
.Exports .example-download button {
    -webkit-appearance: none;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.Imports .example-download img,
.Exports .example-download img {
    height: 20px;
    width: auto;
    margin-right: 10px;
}


/* Running Jobs Indicator */

.JobsIndicator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    position: relative;
}

body.dev .JobsIndicator {
    margin-left: 500px;
}

.JobsIndicator .indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.JobsIndicator .indicator img {
    width: 30px;
    height: 30px;
}

.JobsIndicator .jobs-count-badge,
.JobsIndicator .jobs-failed-badge {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    background-color: #EA2A2A;
    border: 1px solid #9F1818;
    border-radius: 50%;
    color: white;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 12px;
}

.JobsIndicator .indicator.running img {
    -webkit-animation:spin 3s linear infinite;
    -moz-animation:spin 3s linear infinite;
    animation:spin 3s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.JobsIndicator .dropdown {
    position: absolute;
    bottom: -15px;
    right: 0;
    transform: translateY(100%);
    width: fit-content;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #E3E2E7;
    border-radius: 4px;
    box-shadow: 3px 3px 3px 0px rgba(0,0,0,0.2);
    padding: 36px 36px 16px 36px;
    z-index: 1000;
}

.JobsIndicator .dropdown:before,
.JobsIndicator .dropdown:after {
    content: '';
    position: absolute;
    top: -10px;
    right: 4px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid rgba(0,0,0,0.2);
}

.JobsIndicator .dropdown:before {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid rgba(0,0,0,0.2);
}

.JobsIndicator .dropdown:after {
    top: -9px;
    right: 5px;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid white;
}

.JobsIndicator button.refresh {
    position: absolute;
    top: 15px;
    right: 15px;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    color: #48baa8;
    cursor: pointer;
}

.JobsIndicator .dropdown h3 {
    margin-bottom: 11px;
}

.JobsIndicator .dropdown table {
    width: 100%;
    box-sizing: border-box;
}

.JobsIndicator .dropdown .job.has-details {
    cursor: pointer;
}

.JobsIndicator .dropdown .job td {
    padding: 10px 0;
    border-top: 1px solid #E3E2E7;
    vertical-align: middle;
}

.JobsIndicator .dropdown .job td .inner {
    display: flex;
    align-items: center;
    text-transform: none;
}

.JobsIndicator .dropdown .job .time {
    font-size: 10px;
    margin-left: 10px;
    text-transform: none;
}

.JobsIndicator .dropdown .job .type-icon {
    padding-right: 23px;
}

.JobsIndicator .dropdown .job .type-icon img {
    width: 21px;
    height: 21px;
}

.JobsIndicator .dropdown .exports .job .type-icon img {
    position: relative;
    left: 5px;
}

.JobsIndicator .dropdown .job .progress {
    padding-left: 45px;
}

.JobsIndicator .dropdown .job .progress .inner {
    justify-content: flex-end;
}

.JobsIndicator .dropdown .job .progress svg {
    width: 20px;
    height: 20px;
}

.JobsIndicator .dropdown .job .progress .CircularProgressbar-path {
    stroke: #48BAA8;
}

.JobsIndicator .dropdown .job .download {
    position: relative;
    top: 2px;
    cursor: pointer;
}

.JobsIndicator .dropdown .job .download img {
    width: 20px;
    height: 20px;
    margin-left: 15px;
}

.JobsIndicator .dropdown .errors-heading,
.JobsIndicator .dropdown .error {
    color: #ea2a2a;
}

.JobsIndicator .dropdown .error {
    text-transform: none;
}

.JobsIndicator .dropdown .error.exception {
    cursor: pointer;
}

.JobsIndicator .dropdown .no-jobs-message {
    font-style: italic;
}

.JobsIndicator .dropdown .separator {
    height: 32px;
    visibility: hidden;
}

/* ===== Detail Pane START ===== */

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

#detail_pane_mount:empty {
    display: none;
}

.detail-pane-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.detail-pane-wrapper > .close-detail-pane {
    flex: 1;
    width: auto;
    height: 100%;
}

.detail-pane {
    animation: 500ms ease-out 0s 1 slideInFromRight;
    background: white;
    margin-left: auto;
    overflow: auto;
    width: 600px;
    height: 100%;
}

.close-detail-pane,
.open-detail-pane {
    cursor: pointer;
}

.detail-pane hr {
    border: 0;
    border-bottom: 1px solid #e3e2e7;
    margin: 0 30px;
    width: auto;
}


/* Detail Pane - Top Header */
.dp-top-header {
    align-items: center;
    background-color: #F7F7F7;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 15px 30px;
}

.dp-top-header button:not(.btn) {
    border: 0;
    padding: 0;
    background: none;
}

.dp-top-header button.prev-btn {
    border: 1px solid #e3e2e7;
    background-color: #FFFFFF;
    margin-bottom: 0;
    margin-right: 8px;
    color: #000000;
    align-items: center;
    display: flex;
}

.dp-top-header button.next-btn {
    border-color: #48BAA8;
    background-color: #48BAA8;
    margin-bottom: 0;
    color: #FFFFFF;
    align-items: center;
    display: flex;
}

.dp-top-header .prev-btn img {
    margin-right: 10px;
}

.dp-top-header .next-btn img {
    margin-left: 10px;
}

.dp-top-header-left button,
.dp-top-header-right button,
.dp-top-header-right > a {
    margin-right: 15px;
}

.dp-top-header-left button:last-of-type,
.dp-top-header-right button:last-of-type {
    margin-right: 0;
}

.dp-top-header-left,
.dp-top-header-right {
    display: flex;
    align-items: center;
}

.dp-top-header-right .context-menu-wrapper {
    align-self: flex-start;
}

/* Detail Pane - Alert Banner */
.dp-alert-banner {
    background: #ea2a2a;
    color: #fff;
    font-family: Raleway,sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.2px;
    line-height: 14px;
    padding: 5px 30px;
    text-align: center;
    text-transform: uppercase;
}

.dp-post-grid-message {
    font-family: Raleway,sans-serif;
    color: #313233;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .7px;
    margin-bottom: 25px;
    line-height: 20px;
    padding: 10px 30px;
    text-align: center;
    text-transform: none;
}

.dp-post-grid-alert {
    background: #ea2a2a;
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    font-size: 10px;
    height: 15px;
    letter-spacing: 0;
    line-height: 10px;
    padding: 2.5px;
    text-align: center;
    vertical-align: middle;
    width: 15px;
}

/* Detail Pane - Influencer Tier Banner */
.influencer-tier-pill.dp-influencer-tier-pill {
    margin-right: 5px;
    width: fit-content;
    display: inline-block;
}

.influencer-tier-pill.dp-influencer-tier-pill.influencer-tier-1 {
    /* Special style case for Sub-Nano because word is longer that the other tiers and needs to be smaller */
    font-size: 10px;
    letter-spacing: 0;
    padding: 2px 5px;
}

/* Detail Pane - Main Header */
.dp-main-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0;
    line-height: 30px;
    color: #000000;
    padding: 25px 30px;
}

.dp-main-header-left {
    text-align: left;
}

.dp-main-header-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    text-align: center;
    width: 175px;
}

.dp-main-header-name {
    font-size: 24px;
    font-weight: 500;
}

.dp-main-header-right .dp-main-header-right-data {
    border-right: 1px solid #E3E2E7;
    padding-left: 12px;
    padding-right: 12px;
    position: relative;
}

.dp-main-header-right .dp-main-header-right-data:first-of-type {
    padding-left: 0;
}

.dp-main-header-right .dp-main-header-right-data:last-of-type {
    border-right: none;
    padding-right: 0;
}

.dp-main-header-right-value {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
}

.dp-main-header-handle,
.dp-main-header-right-label {
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
}

.dp-main-header-handle {
    color: #48BAA8;
}

/* Detail Pane - Buttons */
.dp-buttons {
    /* display: flex;
    flex-direction: row; */
    padding: 0 30px 15px;
}

.dp-buttons .row-action-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.detail-pane .dp-buttons .data-table-row-action.btn.active {
    background-color: #48baa8;
}

.detail-pane .dp-buttons a.data-table-row-action {
    color: #fff;
}

.detail-pane .dp-buttons .data-table-row-action.split-btn-left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 0;
    border-right: 1px solid #fff;
}

.detail-pane .dp-buttons .data-table-row-action.split-btn-inner {
    border-radius: 0;
    border-left: 1px solid #313233;
    border-right: 1px solid #fff;
    margin-right: 0;
    margin-left: 0;
}

.detail-pane .dp-buttons .data-table-row-action.split-btn-right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: 0;
}
.detail-pane .dp-buttons .data-table-row-action {
    margin-right: 13px;
}

/* Detail Pane - Note */
.dp-note {
    color: #313233;
    font-family: Raleway,sans-serif;
    padding: 15px 30px 15px;
}

.dp-note .dp-add-account-note-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

.dp-account-note-label {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.2px;
    line-height: 14px;
    text-transform: uppercase;
}

.dp-add-account-note-wrapper .dp-account-note-label {
    margin-left: 12px;
}

.dp-account-note-add-btn {
    background: #FFFFFF;
    border: 1px solid #E3E2E7;
    border-radius: 4px;
    cursor: pointer;
    line-height: 0;
    padding: 11px;
}

.dp-plus-img {
    height: 14px;
    width: 14px;
}

.dp-account-note-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.dp-account-note-wrapper-top {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.dp-account-note-edit-btn {
    background: #FFFFFF;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    line-height: 0;
    padding: 0;
}

.dp-edit-img {
    height: auto;
    width: 20px;
}

.detail-pane hr.dp-account-note-divider {
    border: 0;
    border-bottom: 1px solid #E3E2E7;
    margin: 12px 0;
    width: auto;
}

.dp-account-note-display {
    color: #313233;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.7px;
    margin-bottom: 25px;
    line-height: 20px;
    text-transform: none;
}

.dp-account-note-form-wrapper form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.dp-account-note-form-wrapper form .dp-account-note-label,
.dp-account-note-form-wrapper form textarea {
    margin-bottom: 12px;
}

.dp-account-note-form-wrapper form textarea {
    background: #FFFFFF;
    border: 1px solid #E3E2E7;
    border-radius: 4px;
    padding: 11px;
}

.dp-account-note-form-wrapper form .btn {
    background-color: #48BAA8;
}

/* Detail Pane - User Info */
.dp-user-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 25px 30px 0;
    font-family: 'Raleway', sans-serif;
    color: #313233;
}

.dp-user-info-single {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
}

.dp-user-info-label {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.2px;
    line-height: 14px;
    margin-bottom: 12px;
    text-transform: uppercase;
}

.dp-user-info-data {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.7px;
    margin-bottom: 25px;
    line-height: 20px;
    text-transform: none;
}

a.dp-user-info-data {
    color: #48baa8;
}

/* Detail Pane - Row Actions */
.detail-pane .dp-buttons .row-action-buttons {
    justify-content: center;
}

.detail-pane .row-action-btn-separator {
    border: 0;
    display: block;
    width: 100%;
}

tr .original-row-actions .row-action-buttons {
    display: none;
}

.detail-pane .row-action-buttons a.processing {
    opacity: 0.5;
}

table.dataTable tbody tr.active-detail {
    background-color: #F5F5F5;
}

/* Detail Pane - Post Grid (Wrapper) */
.dp-post-tabs .react-tabs__tab {
    font-family: Raleway,sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.2px;
    line-height: 14px;
    text-transform: uppercase;
}

.dp-post-grid {
    padding: 25px 30px;
}

.scrape-recent-posts-btn.btn {
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

/* ===== Detail Pane STOP ===== */

/* ===== Context Menu START ===== */
.context-menu-wrapper {
    position: relative;
}

.context-menu-wrapper .more-btn {
    border: 0;
    background: none;
}

.context-menu {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #E3E2E7;
    border-radius: 4px;
    color: #373a3c;
    font-size: 16px;
    margin: 2px 0 0;
    min-width: 160px;
    outline: none;
    opacity: 0;
    /* display: none; */
    padding: 5px 0;
    position: absolute;
    top: 100%;
    pointer-events: none;
    text-align: left;
    transition: opacity 250ms ease !important;
}

.context-menu.visible {
    opacity: 1;
    /* display: block; */
    pointer-events: auto;
    z-index: 90;
}

.context-menu-outside {
    pointer-events: auto;
    position: fixed;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 89;
}

.context-menu-item,
.context-menu .data-table-row-action.btn {
    background: 0 0;
    border: 0;
    border-radius: 0;
    color: #000;
    cursor: pointer;
    display: block;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    padding: 3px 20px;
    text-align: inherit;
    white-space: nowrap;
}

.context-menu-item.active,
.context-menu-item.selected,
.context-menu .data-table-row-action.btn.active {
    color: #fff;
    background-color: #48BAA8;
    border-color: #48BAA8;
    text-decoration: none;
}

.context-menu-item.disabled,
.context-menu-item.disabled:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
    color: #878a8c;
}

.context-menu-divider {
    border-bottom: 2px solid rgba(100,100,100,1);
    cursor: inherit;
    margin-bottom: 3px;
    padding: 2px 0;
}

.context-menu .data-table-row-action.btn.split-btn-right {
    border-bottom: 2px solid rgba(100,100,100,1);
}

.context-menu .data-table-row-action.btn.split-btn-right:last-of-type {
    border-bottom: 0;
}

.context-menu-divider:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
}

.context-menu-item.context-menu-submenu {
    padding: 0;
}

.context-menu-item.context-menu-submenu > .context-menu-item {

}

.context-menu-item.context-menu-submenu > .context-menu-item:after {
    content: "▶";
    display: inline-block;
    position: absolute;
    right: 7px;
}

/* ===== Context Menu STOP ===== */

/* ===== Instagram Posts Grid START ===== */
.ipg-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.ipg-single {
    aspect-ratio: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 4px;
    margin-bottom: 5px;
    overflow: hidden;
    position: relative;
    height: auto;
    width: 32%;
}

/* Grid Tile Top START */
.ipg-single-top {
    height: calc(100% - 30px);
    position: relative;
    width: 100%;
}

.ipg-post-img-btn {
    background: #f7f7f7;
    border: 0;
    border-radius: 0;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
    width: 100%;
}

.ipg-post-hover-indicator {
    align-items: center;
    background: rgb(255, 255, 255);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: center;
    opacity: 0;
    position: absolute;
    width: 100%;
}

.ipg-post-download-indicator {
    align-items: center;
    color: #313233;
    display: flex;
    flex-direction: row;
    font-family: Raleway, sans-serif;
    font-size: 12px;
    font-weight: 600;
    height: 100%;
    justify-content: center;
    left: 0;
    letter-spacing: 1.2px;
    line-height: 14px;
    position: absolute;
    text-shadow: 1px 1px 3px #fff;
    text-transform: uppercase;
    top: 0;
    width: 100%;
}

.ipg-post-hover-indicator .ipg-expand-img {
    height: 24px;
    width: 24px;
}

.ipg-single:hover .ipg-post-hover-indicator {
    opacity: 0.9;
}

.ipg-post-type-img {
    height: auto;
    width: 16px;
    position: absolute;
    right: 5px;
    top: 5px;
}

.ipg-single:hover .ipg-single-top img.ipg-post-type-img.photo-type {
    content:url("./images/icon-image-dark.svg");
}

.ipg-single:hover .ipg-single-top img.ipg-post-type-img.video-type {
    content:url("./images/icon-video-dark.svg");
}

.ipg-single:hover .ipg-single-top img.ipg-post-type-img.reel-type {
    content:url("./images/icon-reel-dark.svg");
}

.ipg-single:hover .ipg-single-top img.ipg-post-type-img.story-type {
    content:url("./images/icon-story-dark.svg");
}

.ipg-post-img {
    height: auto;
    min-height: 100%;
    object-fit: cover;
    width: 100%;
}
/* Grid Tile Top STOP */

/* Grid Tile Bottom START */
.ipg-single-bottom {
    align-items: center;
    height: 30px;
    background: black;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 6px;
    position: relative;
    width: 100%;
}

.ipg-single-engagement {
    color: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.9px;
    line-height: 30px;
}

.ipg-single:hover .ipg-single-engagement {
    color: #000000;
}

.ipg-single-bottom a,
.ipg-single-bottom img {
    height: 100%;
}

.ipg-single:hover .ipg-single-bottom {
    background: #FFFFFF !important;
}

.ipg-single:hover .ipg-single-bottom img.hourglass-btn {
    content:url("./images/icon-hourglass-dark.svg");
}

.ipg-single:hover .ipg-single-bottom .external-btn img {
    content:url("./images/icon-external-link-dark.svg");
}

/* Grid Tile Bottom STOP */
/* ===== Instagram Posts Grid STOP ===== */

/* ===== Instagram Post Card START ===== */
.ipc-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}

.ipc-close-modal {
    cursor: pointer;
    background: rgba(0,0,0,0.8);
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    z-index: -1;
}

.ipg-post-card-btn {
    background: none;
    border: 0;
    border-radius: 0;
    height: 20px;
    padding: 0;
    margin: 0;
}

.ipg-post-card-exit-btn {
    background: none;
    border: 0;
    height: 35px;
    padding: 0;
    position: absolute;
    margin: 0;
    top: 50px;
    right: 50px;
    width: 35px;
}

.ipg-post-card-exit-btn img {
    height: 35px;
    width: 35px;
}

.ipg-post-card-btn.prev {
    margin-right: 32px;
}

.ipg-post-card-btn.next {
    margin-left: 32px;
}

.ipc-carousel-controls {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    left: 0;
    padding: 24px;
    position: absolute;
    top: 0;
    width: 100%;
}

.ipc-carousel-controls .ipg-post-card-btn.prev,
.ipc-carousel-controls .ipg-post-card-btn.next {
    align-items: center;
    background: #ffffff;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    height: 25px;
    margin: 0;
    width: 25px;
}

.ipc-carousel-controls .ipg-post-card-btn.prev img,
.ipc-carousel-controls .ipg-post-card-btn.next img {
    width: 10px;
}

.instagram-post-card {
    background: #FFFFFF;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    overflow: hidden;
    height: 450px;
    width: 900px;
}

.instagram-post-card .ipc-left,
.instagram-post-card .ipc-right {
    background: #ffffff;
    height: 100%;
    position: relative;
    width: 50%;
}

.ipc-video-overlay {
    background: rgba(0,0,0,0.25);
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 250ms;
}

.ipc-video-overlay:hover {
    background: rgba(255,255,255,0.25);
}

.ipc-video-overlay img {
    height: auto;
    width: 175px;
}

/* Post Card Left START */
.ipc-post-img {
    height: auto;
    min-height: 100%;
    object-fit: cover;
    width: 100%;
}

.ipc-post-type-img {
    background: rgba(0,0,0,0.15);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    height: auto;
    width: 25px;
    position: absolute;
    right: 25px;
    top: 25px;
}

.ipc-download-btn {
    background: #FFFFFF;
    border-radius: 25px;
    display: flex;
    display: none;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    position: absolute;
    right: 25px;
    bottom: 25px;
    overflow: hidden;
    padding: 7px;
    height: 25px;
    width: 25px;
}

/* Post Card Left STOP */

/* Post Card Right START */
.instagram-post-card .dp-main-header {
    padding: 25px;
    height: 110px; /* Must Add to 450 with other sections on right side */
}

.instagram-post-card .dp-main-header .dp-main-header-left {
    width: 58%;
}

.instagram-post-card .dp-main-header .dp-main-header-left .dp-main-header-name {
    white-space: nowrap;
    overflow: hidden;
}

.instagram-post-card .dp-main-header .dp-main-header-right {
    width: 42%;
}

.ipc-post-stats {
    align-items: center;
    background: #F7F7F7;
    border: 1px solid #939AA4;
    border-left: 0;
    border-right: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 54px; /* Must Add to 450 with other sections on right side */
    padding: 10px 25px;
    color:#313233;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
}

.ipc-post-stats .ipc-gathering-stats,
.ipc-post-stats .ipc-likes,
.ipc-post-stats .ipc-comments {
    font-size: 18px;
}

.ipc-post-stats .ipc-gathering-stats {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.ipc-post-stats .ipc-gathering-stats img {
    margin-right: 15px
}

.ipc-post-stats .ipc-likes,
.ipc-post-stats .ipc-comments,
.ipc-post-stats .ipc-engagement {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.ipc-post-stats .ipc-likes img,
.ipc-post-stats .ipc-comments img,
.ipc-post-stats .ipc-engagement .ipc-engagement-label {
    margin-right: 10px;
}

.ipc-post-stats .ipc-engagement .ipc-engagement-label {
    font-size: 14px;
}

.ipc-post-stats .ipc-engagement .ipc-engagement-value {
    color:#000000;
    font-size: 24px;
    font-weight: 700;
}

.ipc-post-caption {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    letter-spacing: 0.7px;
    line-height: 20px;
    overflow-y: auto;
    height: 211px; /* Must Add to 450 with other sections on right side */
    padding: 25px 25px 0;
    text-align: left;
}

.ipc-post-caption .ipc-post-text {
    font-weight: 400;
    color: #313233;
    margin-bottom: 10px;
}

.ipc-post-caption .ipc-post-hashtags,
.ipc-post-caption .ipc-post-mentions {
    color: #48BAA8;
    font-weight: 700;
    text-transform: none;
}

.ipc-post-bottom-bar {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 75px;  /* Must Add to 450 with other sections on right side */
    color:#313233;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
    padding: 25px;
}

.ipc-post-bottom-bar .external-btn,
.ipc-post-bottom-bar .external-btn img {
    height: 25px;
}
/* Post Card Right STOP */

/* ===== Instagram Post Card STOP ===== */

/* ===== Segmentation Filter START ===== */

#segmentation_filter_mount:empty {
    display: none;
}

.SegmentationFilter {
    display: flex;
    flex: 1 1;
    align-items: center;
    height: 100vh;
}

.SegmentationFilter .inner-wrap {
    margin: 0 40px;
    background-color: #fff;
    width: 100%;
    max-height: 90vh;
    position: relative;
    overflow: hidden;
}

/* Header */

.SegmentationFilter .header {
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 24px;
    background-color: #48baa8;
    color: #fff;
    box-sizing: border-box;
}

.SegmentationFilter .header {
    position: absolute;
}

.SegmentationFilter .header .heading {
    display: flex;
    align-items: center;
    height: 50px;
}

.SegmentationFilter .header .heading h2 {
    margin: 0;
    color: #fff;
}

.SegmentationFilter .header .buttons {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
}

.SegmentationFilter .exit-btn {
    -webkit-appearance: none;
    background: none;
    border: none;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    width: 14px;
    height: 14px;
    line-height: 14px;
}


/* Segment Name */

.SegmentationFilter .segment-name {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 24px;
    border-bottom: 1px solid #E3E2E7;
}

.SegmentationFilter .segment-name label {
    margin-right: 5px;
    color: #939AA4;
}

.SegmentationFilter .segment-name input[type=text] {
    padding: 5px 5px;
    margin: 0;
    line-height: 12px;
    border: 1px solid #E3E2E7;
    width: 400px;
    margin-right: 13px;
}


/* Panes */

.SegmentationFilter .panes {
    display: flex;
    overflow: hidden;
    padding-top: 100px;
    padding-bottom: 80px;
    max-height: 90vh;
    box-sizing: border-box;
}

.SegmentationFilter .panes .left {
    display: flex;
    flex-direction: column;
    background-color: #f7f7f7;
    width: 350px;
    border-right: 1px solid #e3e2e7;
}

.SegmentationFilter .panes .right {
    display: flex;
    flex: 1 1;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.SegmentationFilter .panes .buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.SegmentationFilter .panes .left .btn {
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
}

.SegmentationFilter .panes .left .buttons.last .btn:last-of-type {
    margin-bottom: 0;
}

.SegmentationFilter .panes .left-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    margin: 36px 23px;
}

.SegmentationFilter .panes .left-vertical-divider {
    border-bottom: 1px solid #e3e2e7;
}

.SegmentationFilter .panes .left-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    margin: 36px 23px;
}

.SegmentationFilter .panes .left-top h3,
.SegmentationFilter .panes .left-bottom h3 {
    margin-bottom: 30px;
}


/* Pane - Left-Top */

/* Add Filter */

.SegmentationFilter .add-filter {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.SegmentationFilter .add-filter .react-select-container {
    margin-bottom: 20px;
}


/* Pane - Left-Bottom */

/* Load Segment */

.SegmentationFilter .load-segment {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.SegmentationFilter .load-segment .react-select-container {
    margin-bottom: 20px;
}

/* Pane - Right */

.SegmentationFilter .filters {
    position: relative;
    flex: 1 1;
    margin: 30px 22px 30px 35px;
}

.SegmentationFilter .filters:before {
    content: '';
    position: absolute;
    top: -30px;
    left: 0;
    height: 30px;
    border-left: 1px solid #E3E2E7;
}

.SegmentationFilter .no-filters-message {
    position: relative;
    left: -3px;
    top: 5px;
}

.SegmentationFilter .filters table {
    width: 100%;
    margin-left: 37px;
}

.SegmentationFilter .filter-row td {
    position: relative;
    padding: 10px 0 10px 0;
}

.SegmentationFilter .filter-row td:first-of-type:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -37px;
    width: 37px;
    border-bottom: 1px solid #E3E2E7;
}

.SegmentationFilter .filter-row td:first-of-type:after {
    content: '';
    position: absolute;
    top: 0;
    left: -37px;
    height: 100%;
    border-left: 1px solid #E3E2E7;
}

.SegmentationFilter .filter-type {
    display: none;
}

.SegmentationFilter .filter-name {
    background-color: #48BAA8;
    color: #fff;
    border: 1px solid #009780;
    border-radius: 4px;
    padding: 10px;
    margin-right: 20px;
    text-align: center;
    line-height: 12px;
    box-sizing: border-box;
}

.SegmentationFilter .filter-operator {
    margin-right: 20px;
    z-index: 1000;
    min-width: 150px;
}

/* .SegmentationFilter .filter-operator .react-select-container [class*="-menu"] {
  z-index: 1000;
} */

.SegmentationFilter .filter-value {
    margin-right: 20px;
}

.SegmentationFilter .filter-value .text-input {
    width: 100%;
}

.SegmentationFilter .filter-remove {
    margin-right: 20px;
}

.SegmentationFilter .filter-remove button {
    -webkit-appearance: none;
    background: none;
    border: 1px solid #E3E2E7;
    width: 34px;
    height: 34px;
}

.SegmentationFilter .filter-remove button img {
    height: 12px;
    width: auto;
    opacity: 0.6;
    cursor: pointer;
}

.SegmentationFilter .filters-operator {
    position: relative;
    padding: 10px 0 10px 37px;
}

.SegmentationFilter .filters-operator:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 37px;
    border-bottom: 1px solid #E3E2E7;
}

.SegmentationFilter .filters-operator:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 50%;
    border-left: 1px solid #E3E2E7;
}

.SegmentationFilter .filters-operator button {
    padding: 12px 30px;
    border: 1px solid #E3E2E7;
    border-radius: 4px;
    background-color: #fff;
    color: #313233;
    cursor: pointer;
}

.SegmentationFilter .filters-operator button.active {
    background-color: #313233;
    color: #fff;
}

.SegmentationFilter .filters-operator button:first-of-type {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.SegmentationFilter .filters-operator button:last-of-type {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}


/* Bottom Buttons */

.SegmentationFilter .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    height: 80px;
    border-top: 1px solid #e3e2e7;
    background: #fff;
}

.SegmentationFilter .bottom .message {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1 1;
    align-self: flex-start;
    padding: 0 0 0 25px;
    height: 100%;
}

.SegmentationFilter .bottom .bottom-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1 1;
    align-self: flex-end;
    padding: 0 25px 0 0;
    height: 100%;
}

.SegmentationFilter .bottom .bottom-buttons button {
    margin-left: 13px;
}

/*
 * Media Queries
 */

@media (max-height: 750px) {
    .SegmentationFilter .left {
        margin-bottom: -80px;
        z-index: 1;
    }

    .SegmentationFilter .panes .left-top,
    .SegmentationFilter .panes .left-bottom {
        margin: 23px 23px;
    }

    .SegmentationFilter .bottom {
        border-right: 1px solid #e3e2e7;
        z-index: 0;
    }

    .SegmentationFilter .bottom .message {
        padding-left: 375px;
    }
}

/* ===== Segmentation Filter STOP ===== */
