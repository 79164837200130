.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.raleway-semi-bold {
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    color: #313233;
}

.raleway-regular {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    color: #313233;
}

.raleway-12 {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    color: #313233;
}

.raleway-14 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    color: #313233;
}

.montserrat-medium {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    color: #313233;
}

.montserrat-semi-bold {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    color: #313233;
}

.montserrat-bold {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    color: #313233;
}

.montserrat-12 {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 30px;
}

.montserrat-14 {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
}

.montserrat-16 {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 30px;
}

.montserrat-18 {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 30px;
}

.btn {
    padding: 4px 13px;
    color: #fff;
    background-color: #313233;
    border: 1px solid transparent;
    border-radius: 4px;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    cursor: pointer;
    border: none;
    outline-width: 0;
}

button {
    outline-width: 0;
}

.btn-reset {
  -webkit-appearance: none;
  border: none;
  padding: 0;
  margin: 0;
  line-height: 0;
  background: none;
}

.btn:disabled {
    opacity: 0.3;
}

.btn-green {
    background-color: #48BAA8;
    color: #fff;
}

.btn-white {
    background-color: #fff;
    color: #313233;
    border: 1px solid #E3E2E7;
}

.btn-icon {
  position: relative;
  display: inline-block;

  /* .raleway-regular */
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  color: #313233;

  background-color: #fff;
  font-size: 32px;
  line-height: 0;
  padding: 0;

  width: 35px;
  height: 35px;
  box-sizing: border-box;
  border: 1px solid #E3E2E7;
  border-radius: 4px;
  cursor: pointer;
}

.btn-icon > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.flex-column-reverse {
    flex-direction: column-reverse;
}

.flex-row {
    flex-direction: row;
}

.flex-row-reverse {
    flex-direction: row-reverse;
}

.react-select-container {
    border-radius: 4px;
}

.react-select-container > div {
    min-height: 35px;
}

.react-select-container [class*="-control"] {
    box-shadow: none;
    border: 1px solid #E3E2E7;
}

.react-select-container [class*="indicatorContainer"] {
    padding: 6px;
}

.react-select-container [class*="indicatorSeparator"] {
    background-color: #E3E2E7;
}

/* Limit react-select dropdown menu height so it
   doesn't flow outside the screen on small screens */
.react-select-container [class*="-menu"] > [class*="css-"] {
    max-height: 175px;
}

.ui-blocker-container {
    position: relative;
}

.block-ui td,
table.processing th {
    position: relative;
}

.block-ui td:after,
table.processing th:after {
    content: '';
}

.ui-blocker,
.block-ui td:after,
table.processing th:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 100;
}

.tabs {
    position: relative;
    display: flex;
    margin-bottom: 0;
    max-width: 100%;
}

.tabs-hrule {
    margin-bottom: 13px;
    height: 1px;
    background-color: #E3E2E7;
}

.tab {
    list-style: none;
    margin-right: 12px;
    color: #313233;
}

.tab a,
.tab span {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #E3E2E7;
    border-bottom: none;
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 5px 13px;
    cursor: pointer;
    color: #313233;
    text-align: center;
}

.tab a:hover {
    text-decoration: none;
    color: #fff;
    background-color: #48BAA8;
}

.tab.active span {
    color: #fff;
    background-color: #48BAA8;
    border: 1px solid #009780;
    border-bottom: none;
    cursor: default;
}

/* Forms */

.styled-checkbox {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #E3E2E7;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  line-height: 0;
  box-sizing: border-box;
  outline-width: 0;
  cursor: pointer;
  overflow: hidden;
}

.styled-checkbox.checked {
  background-color: #47baa8;
  border-color: #009780;
}

.styled-checkbox input {
  opacity: 0;
  cursor: pointer;
}

.text-input {
  display: inline-block;
  height: 35px;
  box-sizing: border-box;
  padding: 0 14px;
  background-color: white;
  border: 1px solid #E3E2E7;
  border-radius: 4px;

  /* .raleway-semi-bold */
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  color: #313233;

  /* .raleway-12 */
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: #313233;
}

.text-input::placeholder {
  /* .raleway-regular */
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  color: #313233;

  /* .raleway-14 */
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.7px;
  text-transform: uppercase;

  color: #939aa4;
  text-transform: none;
}
